import camelCase from 'lodash.camelcase';

import ApplicationController from '.';

type ToggleMode = 'class' | 'dataset';

type GroupEnabledEvent = CustomEvent<{
  group: string;
  instance: ToggleController;
}>;

export default class ToggleController extends ApplicationController<HTMLElement> {
  static values = {
    mode: { type: String, default: 'class' },
    enabled: String,
    group: { type: String, default: '' },
    singlePerGroup: { type: Boolean, default: false },
    disableWaitForEvent: { type: String, default: '' },
  };

  declare modeValue: ToggleMode;
  declare readonly hasModeValue: boolean;

  declare enabledValue: ToggleMode;
  declare readonly hasEnabledValue: boolean;

  declare groupValue: string;
  declare readonly hasGroupValue: boolean;

  declare singlePerGroupValue: boolean;
  declare readonly hasSinglePerGroupValue: boolean;

  declare disableWaitForEventValue: string;
  declare readonly hasDisableWaitForEventValue: boolean;

  private hasDisableQueued: boolean = false;

  get isEnabled() {
    switch (this.modeValue) {
      case 'class': {
        return this.element.classList.contains(this.enabledValue);
      }

      case 'dataset': {
        return camelCase(this.enabledValue) in this.element.dataset;
      }
    }
  }

  enable() {
    this.hasDisableQueued = false;

    if (this.hasGroupValue && this.groupValue) {
      this.dispatch('enabled', {
        target: window,
        detail: { group: this.groupValue, instance: this },
      });
    }

    switch (this.modeValue) {
      case 'class': {
        return this.element.classList.add(this.enabledValue);
      }

      case 'dataset': {
        return (this.element.dataset[camelCase(this.enabledValue)] = '');
      }
    }
  }

  disable() {
    if (
      this.hasDisableWaitForEventValue &&
      this.disableWaitForEventValue &&
      !this.hasDisableQueued
    ) {
      this.hasDisableQueued = true;

      this.bind(
        this.element,
        this.disableWaitForEventValue,
        () => {
          this.disable();
          this.hasDisableQueued = false;

          this.unbind(this.disableWaitForEventValue);
        },
        {
          once: true,
        },
      );

      return false;
    }

    if (this.hasGroupValue && this.groupValue) {
      this.dispatch('disabled', {
        target: window,
        detail: { group: this.groupValue, instance: this },
      });
    }

    switch (this.modeValue) {
      case 'class': {
        return this.element.classList.remove(this.enabledValue);
      }

      case 'dataset': {
        return delete this.element.dataset[camelCase(this.enabledValue)];
      }
    }
  }

  toggle() {
    if (this.isEnabled) {
      this.disable();
    } else {
      this.enable();
    }
  }

  disableIfSameGroup(event?: GroupEnabledEvent) {
    if (event && event.detail) {
      const { group, instance } = event.detail;

      if (instance === this) {
        return false;
      }

      if (
        group &&
        this.hasGroupValue &&
        this.groupValue &&
        this.singlePerGroupValue
      ) {
        if (this.groupValue === group) {
          this.disable();
        }
      }
    }
  }
}
