import ToggleController from './toggle-controller';

// @ts-expect-error Static values override
export default class extends ToggleController {
  static values = {
    threshold: { type: Number },
    mobileThreshold: { type: Array },
  };

  declare thresholdValue: number;
  declare hasThresholdValue: boolean;

  declare mobileThresholdValue: number[];
  declare hasMobileThresholdValue: boolean;

  connect() {
    super.connect();

    this.onScroll(this.updateFromScroll);
    this.updateFromScroll();
  }

  withinThreshold(value: number) {
    if (this.isMobile && this.hasMobileThresholdValue) {
      const { innerHeight } = window;
      const { scrollHeight } = document.documentElement;
      const [start, end] = this.mobileThresholdValue;

      return (
        value > start && value < scrollHeight - innerHeight - Math.abs(end)
      );
    }

    if (this.hasThresholdValue) {
      return value > this.thresholdValue;
    }

    return false;
  }

  updateFromScroll = () => {
    const { scrollY } = window;

    if (this.withinThreshold(scrollY)) {
      this.enable();
    } else {
      this.disable();
    }
  };
}
