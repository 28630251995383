import '../sass/index.scss';
import 'requestidlecallback-polyfill';

import { Application, ControllerConstructor } from '@hotwired/stimulus';

import StimulusControllerResolver, {
  createViteGlobResolver,
  identifierForGlobKey,
} from './resolver';

const staticControllers = {} as Record<string, ControllerConstructor>;

const staticControllerKeys = Object.keys(staticControllers);

const application = Application.start();

console.debug(
  `%c⏚ Loading static controllers: ${staticControllerKeys.join(', ')}`,
  'color: #aaaa00',
);

Object.entries(staticControllers).forEach(([key, controllerModule]) => {
  application.register(key, controllerModule);
});

StimulusControllerResolver.install(
  application,
  createViteGlobResolver(
    Object.fromEntries(
      Object.entries(
        import.meta.glob('./controllers/**/*-controller.{js,ts}'),
      ).filter(([key]) => {
        const identifier = identifierForGlobKey(key);

        return identifier && !staticControllerKeys.includes(identifier);
      }),
    ),
  ),
);
