import SwupBodyClassPlugin from '@swup/body-class-plugin';
import SwupDebugPlugin from '@swup/debug-plugin';
import SwupHeadPlugin from '@swup/head-plugin';
import SwupParallelPlugin from '@swup/parallel-plugin';
import SwupScriptsPlugin from '@swup/scripts-plugin';
import SwupScrollPlugin from '@swup/scroll-plugin';
import Swup from 'swup';

import ApplicationController from '.';

export default class extends ApplicationController {
  static values = {
    containers: Array,
    debug: Boolean,
  };

  declare containersValue: string[];
  declare readonly hasContainersValue: boolean;

  declare debugValue: boolean;
  declare readonly hasDebugValue: boolean;

  static targets = ['header'];

  declare readonly headerTarget?: HTMLElement;
  declare readonly headerTargets: HTMLElement[];
  declare readonly hasHeaderTarget: boolean;

  private instance?: Swup;

  get hasReducedMotion() {
    return window.matchMedia('(prefers-reduced-motion: reduce)').matches;
  }

  connect() {
    this.excludeAdminBar();
    this.initializeSwup();
  }

  disconnect() {
    if (this.instance) {
      this.instance.destroy();
      delete this.instance;
    }
  }

  excludeAdminBar() {
    const adminBar = document.getElementById('wpadminbar');

    if (adminBar) {
      adminBar.dataset.noSwup = '';
    }
  }

  get basePlugins() {
    const scrollOptions = {
      betweenPages: true,
      samePageWithHash: true,
      samePage: true,
    };

    return [
      new SwupBodyClassPlugin(),

      new SwupHeadPlugin({
        persistTags: '[data-swup-persist]',
        awaitAssets: true,
      }),

      new SwupParallelPlugin(),

      new SwupScrollPlugin({
        animateScroll: this.hasReducedMotion ? undefined : scrollOptions,
      }),

      new SwupScriptsPlugin({
        optin: true,
      }),
    ];
  }

  get plugins() {
    if (this.debugValue) {
      return [...this.basePlugins, new SwupDebugPlugin()];
    }

    return this.basePlugins;
  }

  get visitState() {
    return document.documentElement.dataset.swupVisitState || null;
  }

  set visitState(value: string | null) {
    if (value) {
      document.documentElement.dataset.swupVisitState = value;
    } else {
      delete document.documentElement.dataset.swupVisitState;
    }
  }

  initializeSwup() {
    this.instance = new Swup({
      animateHistoryBrowsing: true,
      containers: this.containersValue,
      plugins: this.plugins,
    });

    this.instance.hooks.on('visit:start', () => {
      this.visitState = 'start';
      this.dispatch('visit:start', { target: window });
      this.dispatch('visit:start', { target: document.documentElement });
    });

    this.instance.hooks.on('visit:end', () => {
      this.visitState = 'end';
      this.dispatch('visit:end', { target: window });
      this.dispatch('visit:end', { target: document.documentElement });
    });

    this.instance.hooks.on('visit:abort', () => {
      this.visitState = 'abort';
      this.dispatch('visit:abort', { target: window });
      this.dispatch('visit:abort', { target: document.documentElement });
    });

    this.instance.hooks.on('animation:out:start', () => {
      this.visitState = 'animation:out:start';
    });
  }
}
