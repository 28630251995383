import ApplicationController from '.';

export default class extends ApplicationController {
  static values = {
    target: { type: String, default: 'window' },
    event: String,
    detail: { type: Object, default: {} },
  };

  declare targetValue?: string;
  declare readonly hasTargetValue: boolean;

  declare eventValue?: string;
  declare readonly hasEventValue: boolean;

  declare detailValue?: string;
  declare readonly hasDetailValue: boolean;

  get target() {
    switch (this.targetValue) {
      case 'self':
        return this.element;

      case 'document':
        return document.documentElement;

      case 'body':
        return document.body;

      case 'window':
      default: {
        return window;
      }
    }
  }

  get detail() {
    if (this.element instanceof HTMLFormElement) {
      const formData = Object.fromEntries(
        Array.from(new FormData(this.element)),
      );

      return formData;
    }

    return this.detailValue;
  }

  dispatchEvent() {
    if (this.hasEventValue && this.eventValue) {
      console.debug(
        `>> dispatcher: dispatchEvent ${this.eventValue}`,
        this.detail,
      );

      this.dispatch(this.eventValue, {
        target: this.target,
        detail: this.detail,
      });
    }
  }
}
