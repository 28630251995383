import gsap from 'gsap';
import ApplicationController from '.';

export default class extends ApplicationController {
  static targets = ['sourceLogo', 'destinationLogo'];

  declare readonly sourceLogoTarget?: HTMLElement;
  declare readonly sourceLogoTargets: HTMLElement[];
  declare readonly hasSourceLogoTarget: boolean;

  declare readonly destinationLogoTarget?: HTMLElement;
  declare readonly destinationLogoTargets: HTMLElement[];
  declare readonly hasDestinationLogoTarget: boolean;

  connect() {
    super.connect();
    this.animateLogo();
  }

  dismiss = () => {
    this.data.set('dismissed', '');
  };

  animateLogo() {
    if (
      this.hasSourceLogoTarget &&
      this.sourceLogoTarget &&
      this.hasDestinationLogoTarget &&
      this.destinationLogoTarget
    ) {
      const fromRect = this.sourceLogoTarget.getBoundingClientRect();
      const toRect = this.destinationLogoTarget.getBoundingClientRect();

      const fromSvg = this.sourceLogoTarget.querySelector('svg');
      const toSvg = this.destinationLogoTarget.querySelector('svg');

      if (fromSvg && toSvg) {
        const timeline = gsap.timeline({
          delay: 2,
          onComplete: this.dismiss,
        });

        timeline
          .to(this.sourceLogoTarget, {
            x: toRect.x - fromRect.x + toRect.width * 0.5,
            y: toRect.y - fromRect.y + toRect.height * 0.5,
            duration: 1.5,
            ease: 'expo.inOut',
          })
          .to(
            fromSvg,
            {
              height: toSvg.clientHeight,
              duration: 1.5,
              ease: 'expo.inOut',
            },
            '<',
          )
          .play();
      }
    }
  }
}
