import ApplicationController from '.';

export default class extends ApplicationController<HTMLElement> {
  static targets = ['background'];

  declare readonly backgroundTarget?: HTMLElement;
  declare readonly hasBackgroundTarget: boolean;

  connect() {
    super.connect();

    this.onScroll(this.updatePageBackground);
    this.updatePageBackground();
  }

  updatePageBackground = () => {
    if (this.hasBackgroundTarget && this.backgroundTarget) {
      const { scrollY, innerHeight } = window;
      const { offsetHeight } = document.documentElement;

      const scrollPercent = scrollY / (offsetHeight - innerHeight);

      this.backgroundTarget.style.setProperty(
        'transform',
        `translate3d(0, ${-scrollPercent * 50}%, 0)`,
      );
    }
  };
}
