import { useMemo } from 'stimulus-use';
import ApplicationController from '.';

type MvpfSubmitEvent = CustomEvent<{ success: boolean }>;

export default class extends ApplicationController {
  static memos = ['form'];

  connect() {
    super.connect();
    useMemo(this);

    if (this.form) {
      this.bind(
        this.form,
        'mvpf:ajax-submitted',
        this.onSubmit as EventListenerOrEventListenerObject,
      );
    }
  }

  get form(): HTMLFormElement | null {
    return this.element.querySelector('form.mvpf__form');
  }

  onSubmit = (event: MvpfSubmitEvent) => {
    const { success } = event.detail;

    if (
      document.activeElement &&
      document.activeElement instanceof HTMLElement &&
      'blur' in document.activeElement
    ) {
      document.activeElement.blur();
    }

    if (success && this.form) {
      this.form.reset();
    }
  };
}
